// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page - roaming.
// -----------------------------------------------------------------------------

#section-roaming {
  position: relative;
  min-height: 100%;

  .section-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    min-height: 100%;
    background: rgba(0,0,0,0.5);
  }

  .section-overlay-offcanvas-left {
    z-index: 2;
    left: -100%;
    background: #fff;
  }

  .section-overlay-offcanvas-right {
    z-index: 2;
    right: -100%;
    background: #fff;
  }

  .section-block-offcanvas {
    position: absolute;
    z-index: 2;
    background: rgba(0, 0, 0, 0.85);
    top: 0;
    width: 100%;
    min-height: 100%;
  }

/*
  ROAMING QUIZ
*/

  .section-roaming-quiz {
    padding: 100px 35px;
    left: -100%;
    @include offCanvasTransition(0.5s);

    > .quiz-block-wrapper {
      @include offCanvasTransition(0.5s);
      opacity: 1;
    }

    .quiz-list {
      margin-top: 50px;
    }

    > .quiz-block-wrapper.is-fadeout {
      opacity: 0;
    }

  }

  .btn-go-back {
    display: none;
    position: absolute;
    z-index: 2;
    top: 8.5em;
    left: 25px;

    img {
      height: 2em;
      width: auto;
    }

  }

  .quiz-title {
    @include font-light;
  }

  .quiz-block {
    opacity: 0;
    @include offCanvasTransition(3s);

    fieldset {
      border: 0;
      margin: 0;
      padding: 0;
    }

    legend {
      font-size: 2.25em;
      @include font-bold;
      display: inline-block;
      padding-top: 5px;
      padding-bottom: 5px;
      border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    }

  }

  .quiz-block.is-active {
    display: block;
  }

  .quiz-list {
    min-height: 250px;

    li {
      font-size: 1.5em;
    }

    li + li {
      margin-top: 20px;
    }
  }

  .quiz-cards {
    width: 100%;

    .quiz-card-inner {
      border: 3px solid #fff;
      border-radius: 10px;
      -webkit-transition: all .2s ease-in-out;
      -moz-transition: all .2s ease-in-out;
      -ms-transition: all .2s ease-in-out;
      -o-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out;
    }

    .quiz-card-inner:hover {
      -webkit-transform: scale(1.02);
      -moz-transform: scale(1.02);
      -ms-transform: scale(1.02);
      -o-transform: scale(1.02);
      transform: scale(1.02);
      cursor: pointer;
    }

    .quiz-card-top {
      padding: 15px;
      border-bottom: 3px solid #fff;
      min-height: 8.825em;
    }

    .quiz-card-content {
      background-color: rgba(115, 115, 115, 0.5);
      min-height: 8.75em;
    }

    .quiz-card-body {
      padding: 15px;
    }

    .deal-wrapper {
      display: inline-block;
      text-align: left;
      padding-right: 15px;
    }

    .deal-title {
      font-size: 1.5em;
      text-transform: uppercase;
    }

    .deal-description {
      font-size: $font-12px;
      color: $grey;
    }

    .deal-property {
      font-size: $font-12px;
    }

    .deal-price {
      margin-top: 5px;
      margin-bottom: 0;
      @include font-bold;
      font-size: $font-32px;
    }

    .deal-day {
      font-size: $font-12px; // 12px
    }

    .quiz-card-footer {
      padding: 0 15px 15px 15px;
      color: #7a7b7b;
      font-size: $font-12px;
    }

  }

  .quiz-card-deals {

    //> li:first-child {
    //  text-align: right;
    //}

    li + li {

      .deal-price {
        position: relative;
        left: -5px;
      }

      .deal-price:before {
        position: relative;
        top: -0.15em;
        left: -15px;
        content: '|';
        display: inline;
        font-family: Helvetica;
        font-size: 0.85em;
      }

      padding-left: 15px;
    }
  }

  .quiz-card-btn:hover {
    cursor: pointer;
  }

  #quiz-next {
    margin-top: 50px;
    min-width: 0;
  }

/*
  ROAMING VIEW ALL DETAILS
*/

  .section-roaming-view-all {
    z-index: 4;
    padding-top: 60px;
    padding-bottom: 60px;
    @include backgroundImageWidget;
    //@include backgroundImageGradient;
    color: #fff;
    right: -100%;
    @include offCanvasTransition(1s);

    .btn-go-back {
      top: 3.35em;
    }

    .quiz-title {
      margin-left: 5px;
      text-transform: uppercase;
      @include font-bold;
    }

    .quiz-cards {

      .quiz-card-content {
        background-color: rgba(255, 255, 255, 0.25);
      }

      .deal-description, .quiz-card-footer {
        opacity: 0.5;
        color: #fff;
      }

    }

  }


/*
  HOME ROAMING
*/

  > .container {
    position: relative;
    padding: 0;
    overflow: hidden;
    min-height: 100%;
  }

  .container-left {
    left: 0;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100%;
    padding: 0;
    @include offCanvasTransition(1s);
  }


  .container-left-inner {
    background-size: cover;
    background-repeat: no-repeat;
  }

  .section-roaming-inner {
    display: table;
    width: 100%;
  }

  .section-roaming-col-left {
    display: table-cell;
  }

  .section-roaming-col-right {
    display: table-cell;
    width: 330px;
  }

  .container-inner {
    padding: 60px 70px;
    color: #fff;
  }

  .roaming-city {
    text-transform: uppercase;
    text-decoration: underline;
  }

  h1 {
    position: relative;
    margin-bottom: 60px;
  }

  h1:after {
    position: absolute;
    content: '';
    bottom: -30px;
    left: 0;
    display: inline-block;
    height: 1px;
    width: 50px;
    border-top: 3px solid $pink;
  }

  .section-roaming-body {
    position: relative;
    margin-top: 100px;
    margin-bottom: 50px;

    .col-right {
      text-align: right;
    }

  }

  .section-roaming-body-inner {

    h1 {
      font-size: 3.375em;
    }
    p {
      margin-top: 60px;
    }

  }

  .tab-view-more {
    position: absolute;
    z-index: 3;
    top: 50%;
    margin-top: -75px;
    right: 0;
    display: inline-block;
    text-transform: uppercase;
    border: 1px solid transparent;
    color: #fff;
    max-width: 315px;
    min-height: 100px;
    text-align: left;

    .chev-right {
      position: absolute;
      top: 50%;
      margin-top: -8px;
      right: 30px;
      height: 16px;
      width: auto;
    }

  }

  .tab-view-more-inner {
    display: block;
    position: relative;
    padding: 30px 95px 30px 30px;
  }

  .tab-view-more-background {
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    @include backgroundImageSvgOrganic1B;
    background-size: 160%;
    background-position: 0 75%;
    opacity: 1;
    //@include backgroundImageGradient;
    // opacity: 0.5;
    width: 100%;
    height: 100%;
  }

  .tab-view-more-label {
    position: relative;
    z-index: 1;
  }

  /*
  .section-roaming-footer {
    margin-top: 30px;
  }
  */

  .section-roaming-body, .section-roaming-footer {
    opacity: 1;
    @include offCanvasTransition(0.5s);
  }

}

#section-roaming.is-roaming-banner {

  .section-roaming-body {
    margin-top: 0;
  }

}


// find my roaming plan

#section-roaming.is-expand {

  > .container {
    overflow-y: hidden;
  }


  .container-inner {
    padding: 0;
  }

  .section-roaming-content {
    position: absolute;
    top: 0;
    left: 0;
    padding: 60px 70px;
  }

  .btn-go-back {
    display: block;
  }

  .section-roaming-quiz {
    position: relative;
    left: 0;
  }

  .section-roaming-body, .section-roaming-footer {
    opacity: 0;
  }

  .quiz-block {
    opacity: 1;
  }

}

// view trending deals

#section-roaming.is-offcanvas {

  .container-left {
    left: -100%;
  }

  .section-roaming-view-all {
    right: 0;

    .quiz-block {
      opacity: 1;
    }

    .btn-go-back {
      display: block;
    }

  }

}

#section-roaming.post-offcanvas {

  .section-roaming-view-all {
    position: relative;
  }

  .container-left {
    position: absolute;
    height: 0;
  }

}

// desktop, tablet
@media screen and (min-width: $screen-md-min) {
   #section-roaming {

     .container-inner {
       position: relative;
     }

     .tab-view-more {
      top: 50%;
      margin-top: -50px;
      min-height: 100px;
     }

     .section-roaming-quiz {
       padding-left: 70px;
     }

     .quiz-cards {

       > li {
        margin-top: 50px;
        float: none;
        display: inline-block;
        vertical-align: top;
       }

       .quiz-card-inner {
         max-width: 22.5em;
       }

     }

    .section-roaming-view-all {
      padding-left: 70px;
      padding-right: 70px;

      .quiz-title {
        margin-top: 0;
      }

      .quiz-cards {

        > li {
          padding-left: 20px;
          padding-right: 20px;
        }

      }

    }

  }

}

// xs, sm, md

@media screen and (max-width: 991px) {

  #section-roaming {

    .quiz-cards {

      > li {
        margin-top: 20px;
      }

    }

  }

}

// mobile
@media screen and (max-width: $screen-xs-max) {

  #section-roaming {

    .container-inner {
      padding: 50px 15px 30px 15px;
    }

    .section-roaming-body-inner {

      h1 {
        font-size: 2.25em;
      }

    }

    .section-roaming-col-left {
      width: 100%;
    }

    .section-roaming-col-right {
      min-width: 0;
    }

    .tab-view-more {
      top: 35px;
      width: 50%;
      margin-top: 0;
      min-height: auto;
      max-width: 16.25em;
    }

    .tab-view-more-inner {
      padding: 15px;
    }

    .tab-view-more-label {
      padding-right: 0;
    }

    .section-roaming-body {
      margin-bottom: 10em;
    }

    .section-roaming-footer {
      position: absolute;
      bottom: 30px;
      width: 100%;
    }

    .btn-invisible {
      width: 100%;
    }

    .section-roaming-quiz {
      padding: 140px 15px 50px 15px;

      .quiz-cards > li {
        padding-left: 0;
        padding-right: 0;
      }

    }

    .btn-go-back {
      top: 50px;
      left: 15px;
    }

    .section-roaming-view-all {

      .quiz-title {
        margin-top: 60px;
        margin-left: 15px;
      }

    }

    .quiz-cards {
      margin-left: 0;
      margin-right: 0;

      > li + li {
        margin-top: 20px;
      }

    }

    .section-roaming-view-all {
      background-position: 20% 20%;
      .quiz-cards {
        margin-top: 30px;
      }

    }

  }

}
