// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page - search.
// -----------------------------------------------------------------------------
.search {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  display: none;

  .container {
    background-color: $bg-search;
    min-height: 100%;
  }

  .close-search-block {
    clear: both;
    overflow: hidden;
  }

  .btn-close-search {
    background: url('../images/search/icon-close-search.png') center center no-repeat;
    background-size: 12px 12px;
    width: 50px;
    height: 50px;
    display: block;
    float: right;
    cursor: pointer;
  }

  .search-field-block {
    padding-top: 20px;

    .search-field {
      border: 0;
      border-bottom: 1px solid $black;
      background: transparent;
      outline: none;
      width: 40%;
      margin: 0 auto;
      display: block;
      text-align: center;
      font-size: $font-60px;
      @include font-light;
    }

    .btn-search {
      ////background: url('../images/search/btn-search.png') no-repeat;
      ////background-size: contain;
      width: 100px;
      height: 100px;
      border: 0;
      margin: 25px auto 0;
      display: block;
      
      img {
        width: 100%;
        height: auto;
      }
      
    }
  }

  .trending-now-block {
    padding: 0 90px 0 35px;
  }

  .trending-item-block {
    margin-bottom: 30px;
  }

  .trending-now-block h2,
  .quick-links-block h2 {
    font-size: $font-32px;
    margin: $padding9x 0;
  }

  .links-list {
    li {
      margin-bottom: 30px;

      &:before {
        /* For a round bullet */
        content: '\2022';
        display: block;
        position: relative;
        max-width: 0;
        max-height: 0;
        left: 0;
        top: 0;
        color: $pink;
        font-size: $font-16px;
      }

      a {
        font-size: $font-16px;
        color: $black;
        margin-left: $padding3x;
      }
    }
  }

  /* Is Searching Loading Screen */
  .section-is-searching {
    .loading-placeholder {
      width: 135px;
      margin: 100px auto 0;
      display: block;
    }
  }
  
  /* Search Results - None */
  
  .section-is-no-results {
    padding-top: 50px;
    text-align: center;
  }

  /* Search Results */
  .search-result-category-heading {
    padding-top: 50px;
    text-align: center;
  }

  .category-heading-list {
    > li {
      display: inline-block;
      padding: 0 10px;
      margin-right: 40px;

      &.is-active {
        border-bottom: 4px solid $pink;

        a {
          color: $pink;
        }
      }

      a {
        color: $black;
        font-size: $font-32px;
      }
    }
  }

  .search-result-list {
    padding: 70px 0;
  }

  .search-result {
    margin-bottom: 60px;
  }

  .search-result-body {
    h2 {
      font-size: $font-48px;
    }
    p {
      font-size: $font-20px;
      color: $dark-grey;
      margin: 5px 0 0 0;
    }
  }

  /*
  .search-result-panel {
    display: none;

    &.active {
      display: block;
    }
  }
  */

}

.search.is-expand {
  display: block !important;
}


@media screen and (max-width: $screen-xs-max) {
  .search {
    .trending-now-block {
      padding: 0 15px;
    }

    .search-field-block {
      .search-field {
        width: 100%;
        margin: 0;
        display: inline-block;
        vertical-align: bottom;
        font-size: $font-34px;
      }

      .btn-search {
        display: inline-block;
        vertical-align: bottom;
        width: 60px;
        height: 60px;
        margin: 0;
      }
    }

    .trending-now-block h2,
    .quick-links-block h2 {
      font-size: $font-18px;
      margin: $padding4x 0;
    }

    /* Search Results */
    .search-result-category-heading {
      padding-top: 25px;
      text-align: center;
    }

    .category-heading-list {
      > li {
        margin-right: 20px;

        &:last-child {
          margin: 0;
        }

        a {
          font-size: $font-14px;
        }
      }
    }

    .search-result {
      margin-bottom: $padding6x;
    }

    .search-result-list {
      padding: 35px 15px;
    }

    .search-result-body {
      h2 {
        font-size: $font-16px;
      }
      p {
        font-size: $font-12px;
        @include font-light;
      }
    }
  }
}