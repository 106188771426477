// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
#root {
  height: 100%;
}

.row-height-100 {
  height: 100%;
}

html {
  box-sizing: border-box;
  background-color: #f2f2f2;
}

html,body { height: 100%; margin: 0px; padding: 0px; }

// main {
//   margin-top: 4.5em;
// }

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: $link-color;
  text-decoration: none;

  @include on-event {
    color: $text-color;
    text-decoration: underline;
  }
}

a.btn-primary:focus {
  color: #ffffff;
}

a.btn-secondary:focus {
  color: #000000;
}

ul, ol {
  margin: 0;
  padding: 0;
}

ul {

  li {
    list-style-type: none;
  }

}

/* Mobile  */
@media screen and (max-width: 767px ) {
  // main {
  //   margin-top: 3.75em;
  // }
}

*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
