// -----------------------------------------------------------------------------
// This file contains all styles related to the icon component.
// -----------------------------------------------------------------------------

figure {
  margin: 0;
}

// Circle icon
.circle-icon {
  // background-color: $white;
  // border-radius: 50%;
  max-width: 200px;
  height: 200px;
  // text-align: center;
  // line-height: 200px;
  display: block;
  // box-shadow: 0 5px 5px 1px #cccccc;


  .icon {
    background-repeat: no-repeat;
    background-size: contain;
    vertical-align: middle;
    display: inline-block;
  }
}

// Jumbortron
.icon-three {
  width: 60px;
  height: 80px;
  vertical-align: sub;
}

.icon-headline-container {
  text-align: center;
}

.icon-headline {
  border-bottom: 1px solid $black;
  padding: 0 $padding3x $padding2x;
  display: inline-block;

  &:after {
    content: '';
    border-bottom: 1px solid $black;
  }

  .logo-three-sm {
    background: url('../images/logo/logo-three-sm.png') no-repeat;
    background-size: 25px 31px;
    width: 25px;
    height: 31px;
    display: inline-block;
    vertical-align: middle;
  }
}

.icon-check-bill {
  //background-image: url('../images/icons/icon-check-bill.png');
  width: 100%;
  height: 100%;
}

.icon-latest-news {
  //background-image: url('../images/icons/icon-latest-news.png');
  width: 100%;
  height: 100%;
}

/* Footer - Social Icons */
.icon-online-support {
  background: url('../images/icons/icon-online-suppport.svg');
  background-size: 22px 22px;
  height: 22px;
}

.icon-hotline {
  background: url('../images/icons/icon-hotline.svg');

}

.icon-cs {
  background: url('../images/icons/icon-cs.svg');
}

.icon-email {
  background: url('../images/icons/icon-email.svg');
}

/* Mobile / Tablet */
@media screen and (max-width: $screen-sm-max ) {
  .circle-icon {
    width: 120px;
    height: 120px;
    margin: 0 auto;
  }

  .icon-three {
    width: 30px;
    height: 40px;
  }

}