button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
  border-radius: none;
}
button,
select {
  text-transform: none;
}
button,
select,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  border-radius: 0;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
          box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: bold;
}


// Checkbox

input[type="checkbox"] {
  margin-right: 20px;
  opacity: 0;
  width: 0;
  height: 0;
  
  + label {
    position: relative;
    top: -0.075em;
  }
}

.is-checkbox {
  position: relative;
  display: inline-block;
  padding: 10px;
  border: 3px solid #fff;
  
  img {
    display: none;
    position: absolute;
    top: 0;
    left: 2px;
    width: 100%;
    height: auto;
  }
  
}

.is-checkbox.is-checked {
  img {
    display: inline;
  } 
}

.is-checkbox:hover {
  cursor: pointer;  
}

// Radio

input[type="radio"] {
  margin-right: 20px;
  opacity: 0;
  width: 0;
  height: 0;
  
  + label {
    position: relative;
    top: -0.15em;
  }
}

.is-radio {
  position: relative;
  display: inline-block;
  padding: 10px;
  border: 3px solid #fff;
  border-radius: 50%;
  
  img {
    display: none;
    position: absolute;
    top: 20%;
    left: 21%;
    width: 60%;
    height: auto;
  }
  
}

.is-radio.is-checked {
  img {
    display: inline;
  } 
}