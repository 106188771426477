// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

// CHECK YOUR BILL
.section-your-bill {
  overflow: hidden;

  .panel {
    // min-height: 500px;
    height: 100%;
  }

  .bg-grey {
    background-color: $bg-panel-2;
  }

  .panel-your-bill {
    background-color: $bg-panel-1;
    padding: 100px;
  }

  .icon-headline-container {
    h1, p {
      text-align: left;
    }

    h1 {
      margin: $padding4x 0 -15px;
      white-space: nowrap;
    }
  }

  .panel-your-bill-login {
    background-color: $bg-panel-2;
    padding: $padding9x 60px 0;
    // padding-bottom: 9999px;
    // margin-bottom: -9999px;

    .btn {
      line-height: 60px;
      height: 60px;
      padding: 0 30px;
      text-transform: uppercase;
    }

    .col-your-number {
      text-align: center;

      .user-mobile-number {
        font-size: $font-40px;
      }
    }

    .col-check-now {
      margin-top: 40px;
      position: relative;

      .btn {
        margin-bottom: 20px;
      }
    }

    // .btn-check-now {
    //   position: relative;
    //   top: 50%;
    //   transform: translateY(-50%);
    // }

    .row-btn-group {
      margin-bottom: $padding6x;
    }

    .form-group {
      margin-bottom: $padding8x;
    }

    label {
      display: block;
      font-size: $font-14px;
      margin-bottom: $padding3x;
    }

    .input-container {
      border-bottom: 1px solid $black;
      position: relative;
      padding: 0 25px 14px 12px;

      position: relative;

      &:before {
        content: '';
        background: url(../images/input-border.png) no-repeat;
        width: 2px;
        height: 32px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .password-eye {
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -6px;
      display: inline-block;
      width: 20px;
      height: 12px;
    }

    .password-eye:hover {
      cursor: pointer;
    }

    /*
    .input-password-container {
      &:after {
        content: '';
        background: url(../images/icons/icon-eye.png) no-repeat;
        background-size: 20px 12px;
        width: 20px;
        height: 12px;
        display: block;
        position: absolute;
        right: 0;
        bottom: 20px;
      }
    }
    */

    input[type="text"],
    input[type="password"] {
      height: 35px;
      width: 100%;
      font-size: $font-32px;
      @include font-light;
      border: none;
      padding: 0;
      background-color: transparent;

      &::placeholder {
        color: #f2f2f2;
        @include font-thin;
        line-height: 1;
      }
      &:-moz-placeholder {
        color: #f2f2f2;
        @include font-thin;
        line-height: 1;
      }
      &::-moz-placeholder {
        color: #f2f2f2;
        @include font-thin;
        line-height: 1;
      }
      &:-ms-input-placeholder {
        color: #f2f2f2;
        @include font-thin;
        line-height: 1;
      }
    }

    .col-forgot-password {
      text-align: right;
      padding-top: 15px;
    }

    .forgot-password-link {
      color: $black;
      font-size: $font-12px;
      text-decoration: underline;
      vertical-align: middle;
    }
  }
}

// ROAMING

@import 'home/_roaming';


// LATEST NEWS

.section-latest-news {
  .container {
    background-color: $bg-panel-1;
  }

  .panel {
    padding: 75px 100px;
  }

  h1 {
    margin-top: 50px;
    white-space: nowrap;
  }

  .btn-group {
    margin-top: 30px;

    .btn {
      margin-left: auto;
      margin-right: auto;
      line-height: 60px;
      height: 60px;
      padding: 0 30px;
      text-transform: uppercase;
    }
  }
}


// BUNDLE

@import 'home/_bundle';


// STORE LOCATOR
.section-store-locator {
  height: 800px;
}


// SEARCH
@import 'home/_search';



/* Mobile / Tablet */
@media screen and (max-width: $screen-sm-max ) {

  .section-your-bill {
    .panel {
      padding: $padding8x 100px;
    }

    .icon-headline-container {
      margin-top: $padding6x;

      h1 {
        text-align: center;
        margin: $padding4x 0;
      }
    }

    .icon-headline {
      font-size: $font-20px;
    }

    .btn-primary {
      width: 100%;
    }
  }

  .section-latest-news {

    .panel {
      padding: $padding8x 100px;
    }

    h1 {
      margin: $padding4x 0;
      text-align: center;
    }

    .btn-group {
      margin: 0;

      .btn {
        padding: 0 15px;
      }
    }

    .btn-secondary {
      background-color: $black;
      color: $white;
    }
  }
}
