// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.btn {
  display: block;
  border: 1px solid $black;
  cursor: pointer;
  color: $white;
  text-decoration: none;
  // text-transform: uppercase;
  text-align: center;
  // height: 60px;
  // line-height: 60px;
  padding: 15px 60px;
  min-width: 315px;

  &:hover {
    background-color: $grey;
    color: $white;
    text-decoration: none;
  }

  &:active {
    background-color: $dark-grey;
    color: $white;
    text-decoration: none;
  }
}

.btn-primary {
  background-color: $black;
}

.btn-secondary {
  background-color: $white;
  color: $black;
}

.btn-invisible {
  background-color: rgba(255,255,255, 0);
  border: 1px solid #fff;
  color: #fff;
  text-transform: uppercase;
}

.btn-invisible:disabled {
  opacity: 0.5;
}

.btn-invisible:hover {
  background-color: rgba(255,255,255, 0.35);
}

.btn-fluid {
  width: 100%;
  min-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.btn-image {
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
}

.btn-group {
  .btn {
    margin-bottom: $padding4x;
  }
}

/* Mobile / Tablet */
@media screen and (max-width: $screen-sm-max ) {
  .btn-primary {
    font-size: $font-14px;
  }
}

/* Mobile */
@media screen and (max-width: $screen-xs-max) {

  .btn {
    min-width: 100%;
    width: 100%;
  }

  .btn-invisible {
    padding: 20px 60px;
  }

}