// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page - bundle.
// -----------------------------------------------------------------------------

.section-bundle {
  .container {
    background: url('../images/background/pink-to-purple.png');
    background-size: contain;
    padding: 60px 70px;
  }

  h1 {
    color: $white;
    font-size: $font-48px;
  }

  p {
    color: $white;
  }

  .col-hr {
    hr {
      opacity: 0.35;
      height: 2px;
      margin: $padding10x 0;
    }
  }

  .note {
    display: inline-block;
  }

  .bundle-card-container {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;

    figure {
      min-height: 180px;
    }

  }

  .bundle-card-top {
    opacity: 1;
  }

  .choice-selector {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    .btn {
      width: 100%;
      margin-bottom: $padding5x;
    }
  }

  .steps-container {
    color: $white;

    h2 {
      font-size: $font-32px;
      display: inline-block;
    }

    .step {
      font-size: $font-16px;
      margin-bottom: $padding2x;
    }

    .btn-one-step-back {
      background: url('../images/icons/icon-arrow-left-white.png') no-repeat;
      background-size: contain;
      // background-size: 15px 24px;
      width: 15px;
      height: 24px;
      display: inline-block;
      position: absolute;
      top: 0;
      left: -3%;
      display: none;
    }
  }

  .bundle-phone-widget {
    margin-top: $padding6x;

    .btn-prev {
      background-image: url('../images/icons/icon-arrow-left-white.png');
      left: -3%;
    }

    .btn-next {
      background-image: url('../images/icons/icon-arrow-right-white.png');
      right: -3%;
    }
  }

  .bundle-card {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: $white;
    margin: 0 $padding3x;
    text-decoration: none;
    cursor: pointer;
    transition: all .2s ease-in-out;

    figure {
      text-align: center;
      position: relative;
      img {
        // width: 100%;
        max-width:100%;
        margin: auto;
        height: auto;
      }
    }

    .bundle-card-top {
      padding: $padding7x $padding5x;
    }

    .choice-selector {
      padding: $padding7x $padding5x;
    }

    .phone-model {
      font-size: $font-24px;
      @include font-bold;
      color: $black;
      text-align: center;
      margin-top: $padding10x;
    }
  }

  .slick-dots {
    bottom: -50px;
  }

  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  .view-more-link {
    font-size: $font-20px;
    color: $white;
    text-decoration: underline;
  }


  &.is-active {
    .steps-container {
      .btn-one-step-back {
        display: inline-block;
      }
    }

    .step-1 {
      .bundle-card {
        opacity: 0.35;

        &:hover {
          transform: scale(1);
        }

        &.is-selected {
          opacity: 1;

          .bundle-card-top {
            opacity: 0.35;
          }
          .choice-selector {
            opacity: 1;
          }
        }
      }
    }
  }

  .bundle-plan-container {
    margin-top: $padding6x;
  }

  .bundle-phone-content {
    padding: 0 $padding6x $padding6x;
    text-align: center;

    hr {
      border-bottom: 2px solid $black;
      margin: $padding5x 0;
    }

    .phone-property {
      color: $black;
      font-size: $font-24px;
      @include font-bold;
      // text-align: center;
    }
  }

  .bundle-card-content {
    padding: $padding6x;
    text-align: center;
    border-bottom: 3px solid $white;

      hr {
      border-top: 2px solid $white;
      margin: $padding4x 0 $padding6x;
    }

    .plan-type {
      font-size: $font-32px;
    }

    .plan-price {
      font-size: 3.2em;
      @include font-bold;
      margin: 0;

      span {
        @include font-thin;
      }
    }

    .plan-duration {
      font-size: $font-20px;
      opacity: 0.5;
    }
  }

  .bundle-card-footer {
    background: rgba(255, 225, 255, 0.1);
    padding: $padding3x $padding4x;
    min-height: 120px;

    p {
      font-size: $font-24px;
      @include font-bold;
      margin: 0;
    }

    .note {
      font-size: $font-14px;
      opacity: 0.5;

    }
  }
  .step-1,
  .step-2 {
    .bundle-card {
      &:hover {
        transform: scale(1.05);
      }
    }
  }


  .step-2,
  .step-3 {
    .bundle-card {
      border: 3px solid $white;
      background: rgba(255, 255, 255, 0.1);
      color: $white;
    }

    .bundle-phone {
      background: $white;
      color: $black;
    }
  }

  .step-3 {
    margin-top: $padding6x;

    .row-bundle-summary {
      margin-top: $padding6x;
    }

    .bundle-summary-container {
      /* li:last-child:before {
        content: '';
        position: absolute;
        left: 0;
        top: 30px;
        height: 85%;
        border-right: 1px solid $white;
        display: inline-block;
      } */
      li:last-child {
        border-left: 1px solid $white;
      }

      .bundle-summary-inner {
        padding: 0 50px;
      }

      .description {
        font-size: $font-24px;
        opacity: 0.5;
      }

      .bundle-price {
        font-size: $font-70px;
        margin: 0;

        span {
          @include font-thin;
        }
      }

      .note {
        font-size: $font-16px;
        color: $white;
        opacity: 0.5;
      }
    }

    .col-plan-details {
      margin-top: $padding10x;

      .btn-invisible {
        margin: 0 auto;
      }
    }
  }

  .step-2, .step-3 {
    display: none;
  }

  // STEP 2


}


@media screen and (min-width: $screen-md-max) {
  .col-md-5-eq {
    width: 16.5%;
    margin: 0 15px;
    padding: 0;
    float: left;
  }
}

/* Mobile / Tablet */
@media screen and (max-width: $screen-sm-max ) {
  .section-bundle {
    .container {
      padding: $padding9x $padding4x;
    }

    h1 {
      font-size: $font-32px;
    }

    p {
      font-size: $font-14px;
      @include font-light;
      margin-bottom: 0;
    }

    .col-hr {
      hr {
        margin: $padding6x 0 $padding4x;
      }
    }

    .steps-container {
      padding-left: $padding7x;
      .step {
        font-size: $font-12px;
        @include font-light;
        margin-bottom: 5px;
      }

      h2 {
        font-size: $font-20px;
        display: inline-block;
      }

      .btn-one-step-back {
        top: -50%;
        left: -5%;
      }
    }

    .slider {
      margin: $padding6x 0;
    }

    .bundle-card {

      .bundle-card-top,
      .choice-selector {
        padding: 0;
      }

      .phone-model {
        font-size: $font-14px;
      }
    }

    .view-more-link {
      font-size: $font-14px;
    }

    /** STEP 1 STYLE **/
    .step-1 {
      .bundle-card {
        padding: $padding6x;
      }
    }

    /** STEP 2 STYLE **/
    .step-2,
    .step-3 {
      .bundle-card {
        margin: 0 0 $padding4x;
      }
    }

    // Bundle phone
    .bundle-phone-content {
      padding: 0 0 0 $padding8x;

      hr {
        margin: $padding2x;
      }
    }

    .bundle-phone {
      padding: $padding3x $padding6x;

      figure {
        min-height: auto;
      }

      span {
        display: block;
        text-align: left;
        margin: $padding3x 0;
      }
    }

    // bundle plan
    .bundle-card-content {
      padding: $padding3x $padding6x;

      .plan-type {
        font-size: $font-20px;
      }

      .plan-price {
        font-size: $font-40px;
      }

      .plan-duration {
        font-size: $font-14px;
      }

      hr {
        border-top: 1px solid $white;
        margin: $padding4x;
      }
    }

    .bundle-card-footer {
      padding: $padding3x $padding6x;
      min-height: auto;

      p {
        font-size: $font-14px;
      }

      .note {
        font-size: $font-10px;
      }
    }

    /** STEP 3 STYLE **/
    .step-3 {
      margin-top: 0;

      .steps-container {
        .btn-one-step-back {
          top: 0;
        }
      }

      .bundle-card-container {
        display: table;
        overflow: hidden;
      }

      .bundle-card-content,
      .bundle-card-footer {
        display: table-cell;
        float: none;
        padding: $padding5x $padding4x;
        vertical-align: top;
      }

      .bundle-card-content {
        border: 0;

        .content-body {
          text-align: left;
          margin-top: $padding6x;
        }

        .plan-type,
        .plan-price {
          font-size: $font-32px;
          display: inline-block;
        }

        .plan-type {
          &:after {
            content: ' | ';
            display: inline-block;
            position: relative;
            height: 100%;
            width: 1px;
            @include font-thin;
            padding: 0 $padding2x;
          }
        }

        .plan-duration {
          opacity: 1;
          display: block;
          position: absolute;
          top: $padding5x;
          left: $padding4x;
        }
      }

      .bundle-summary-container {
        .bundle-summary-inner {
          padding: 0 $padding3x;
        }

        .description {
          font-size: $font-14px;
          @include font-bold;

        }

        .bundle-price {
          font-size: $font-60px;
          @include font-bold;
        }

        .note {
          font-size: $font-14px;
        }

        li:last-child {
          border: 0;
        }
      }
    }
  }
}