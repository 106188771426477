// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
@font-face {
    font-family: 'Helvetica Neue Medium';
    font-weight: bold;
    font-style: normal;
    src: url('../fonts/HelveticaNeue-Medium.eot');
    src: url('../fonts/HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HelveticaNeue-Medium.woff2') format('woff2'),
        url('../fonts/HelveticaNeue-Medium.woff') format('woff'),
        url('../fonts/HelveticaNeue-Medium.otf') format('opentype'),
        url('../fonts/HelveticaNeue-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue Bold';
  font-weight: bold;
  src: url('../fonts/HelveticaNeue-Bold.eot');
  src: url('../fonts/HelveticaNeue-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/HelveticaNeue-Bold.woff2') format('woff2'),
       url('../fonts/HelveticaNeue-Bold.woff') format('woff'),
       url('../fonts/HelveticaNeue-Bold.otf') format('opentype'),
       url('../fonts/HelveticaNeue-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue Light';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/HelveticaNeue-Light.eot');
  src: url('../fonts/HelveticaNeue-Light.eot?#iefix') format('embedded-opentype'),
       url('../fonts/HelveticaNeue-Light.woff2') format('woff2'),
       url('../fonts/HelveticaNeue-Light.woff') format('woff'),
       url('../fonts/HelveticaNeue-Light.otf') format('opentype'),
       url('../fonts/HelveticaNeue-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue Thin';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/HelveticaNeue-Thin.eot');
  src: url('../fonts/HelveticaNeue-Thin.eot?#iefix') format('embedded-opentype'),
       url('../fonts/HelveticaNeue-Thin.woff2') format('woff2'),
       url('../fonts/HelveticaNeue-Thin.woff') format('woff'),
       url('../fonts/HelveticaNeue-Thin.otf') format('opentype'),
       url('../fonts/HelveticaNeue-Thin.ttf') format('truetype');
}