// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font: 'Helvetica Neue Medium', 'Helvetica', Arial, sans-serif !default;

$black: #000000;
$white: #ffffff;
$pink: #e6007e;
$purple: #632080;
$grey: #737373;
$dark-grey: #333333;
$light-grey: #b2b2b2;
$lighter-grey: #d6d6d6;
$lighter-grey-2: #d9d9d9;
$bg-panel-1: #e6e6e6;
$bg-panel-2: #d9d9d9;
$bg-search: rgba(242, 242, 242, 0.97);

$text-color: $black;
$disabled-color: $lighter-grey;
$link-color: $pink;
$link-hover-color: $purple;
$link-active-color: $dark-grey;
$footer-text-color: #929292;

// Font Size
$font-10px: 0.625em;
$font-12px: 0.75em;
$font-14px: 0.875em;
$font-16px: 1em;
$font-18px: 1.125em;
$font-20px: 1.25em;
$font-24px: 1.5em;
$font-32px: 2em;
$font-34px: 2.215em;
$font-36px: 2.25em;
$font-40px: 2.5em;
$font-48px: 3em;
$font-60px: 3.75em;
$font-64px: 4em;
$font-70px: 4.375em;

// Padding
$padding1x: 5px;
$padding2x: 10px;
$padding3x: 15px;
$padding4x: 20px;
$padding5x: 25px;
$padding6x: 30px;
$padding7x: 35px;
$padding8x: 40px;
$padding9x: 45px;
$padding10x: 50px;
$padding11px: 55px;

/// Container's maximum width
/// @type Length
$max-width: 1600px !default;


/// Breakpoints
$screen-sm-max: 767px;
$screen-md-min: 768px;
$screen-md-max: 1024px;
$screen-lg-min: 1200px;
$screen-lg-max: 1599px;

// Font Mixins
@mixin font-regular {
  font-family: 'Helvetica Neue Medium', 'Helvetica', Arial, sans-serif;
  font-weight: normal;
}

@mixin font-light {
  font-family: 'Helvetica Neue Light', 'Helvetica', Arial, sans-serif;
  font-weight: lighter;
}

@mixin font-bold {
  font-family: 'Helvetica Neue Bold', 'Helvetica', Arial, sans-serif;
  font-weight: normal;
}

@mixin font-thin {
  font-family: 'Helvetica Neue Thin', 'Helvetica', Arial, sans-serif;
  font-weight: normal;
}



/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;
