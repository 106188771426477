// -------------------------------------------------------------------------------
// This file contains all styles related to the navigation of the site/application.
// -------------------------------------------------------------------------------

$navbar-height: 4.5em;
$navbar-xs-height: 3.75em;

/*
NAV BAR TOP
*/

// common styling desktop/mobile
.navbar-top {
  position: relative;
  float: left;
  width: 100%;
  background-color: #f2f2f2;

  > .container {
  position: relative;
  z-index: 1;
  background-color: #ffffff;
  -webkit-box-shadow: 0 20px 20px -25px #666;
     -moz-box-shadow: 0 20px 20px -25px #666;
          box-shadow: 0 20px 20px -25px #666;
  }

  .navbar-top-left {
     float: left;
  }

  .navbar-top-right {
    float: right;
    min-width: 25%;
  }

  .navbar-icon {
    width: auto;
    height: 24px;
    vertical-align: middle;
  }

  .navbar-label {
    @include font-regular;
    font-size: 0.875rem;
    color: #000;
    text-transform: uppercase;
    line-height: $navbar-height;
  }

  .v-align-middle {
      display: inline-block;
      height: 100%;
      vertical-align: middle;

      + * {
        vertical-align: middle;
      }
  }

  .navbar-top-list {
    margin-top: 0;

    li {
      display: inline-block;
    }

  }

  .navbar-searchIcon-xs, .navbar-hamburger-xs {
      display: none !important;
  }

  .navbar-hamburger-xs {
    margin-right: 15px;
  }


}

#navbar {
    // position: fixed;
    position: relative;
    z-index: 9990;
    top: 0;
    width: 100%;
    height: 60px;

    .navbar-top-left-list {
      margin-left: 15px;

      .navbar-body-anchor {
        position: relative;
        padding-left: 30px;
        padding-right: 30px;
        text-decoration: none;
      }

      .navbar-body-anchor:after {
        position: absolute;
        bottom: -1.875em;
        left: 0;
        right: 0;
        content: '';
        display: inline-block;
        height: 3px;
        width: 0;
        @include backgroundImageGradient;
      }

      /*
      .navbar-body-anchor:hover:after {
        width: 100%;
      }
      */

      .navbar-body-anchor.is-active:after  {
        width: 100%;
      }

      .navbar-body-anchor.is-post-active:after {
        width: 100%;
      }

      .navbar-body-anchor.is-going-left:after {
        left: 0;
        width: 0%;
       -webkit-transition: all 0.25s ease-in-out;
       -moz-transition: all 0.25s ease-in-out;
       -o-transition: all 0.25s ease-in-out;
       transition: all 0.25s ease-in-out;
      }

      .navbar-body-anchor.is-going-right:after {
        left: initial;
        right: 0;
        width: 0%;
       -webkit-transition: all 0.25s ease-in-out;
       -moz-transition: all 0.25s ease-in-out;
       -o-transition: all 0.25s ease-in-out;
       transition: all 0.25s ease-in-out;
      }

      li + li {
        //margin-left: 60px;
        margin-left: -6px;
      }

    }

    .navbar-logo-block {
      padding-right: 30px;
      position: relative;
      height: $navbar-height;

      a {
        display: inline-block;
      }

    }

    .navbar-logo {
      height: $navbar-height - 1.875em;
      width: auto;
    }

    .navbar-top-right-list {
      margin-left: 0;
      display: inline;

      li {
        height: $navbar-height;
      }

      li:hover {
        cursor: pointer;
      }

      li + li {
        margin-left: 30px;
      }

      > li > a {
        display: inline-block;
        color: #000;
        text-transform: uppercase;
        @include font-regular;
      }

      // login button
      .subnavlogin-wrapper {

        /* .navbar-icon {
          vertical-align: text-bottom;
        } */

        span {
          // display: inline-block;
          // vertical-align: middle;
          text-transform: none;
          margin-left: 10px;
          font-size: $font-12px;
        }
      }

    }

    .navbar-searchIcon{
        float:right;
        // padding-left: 35px;
        // padding-right: 35px;
        @include backgroundImageSvgOrganic2B;
        background-size: 500%;
        background-position: 0 95%;
        // @include backgroundImageGradient;
        min-height: $navbar-height;

        .btn-image {
          width: 210px;
        }

        .navbar-label {
          color: #fff;
          margin-left: 30px;
        }
    }



/*
NAVBAR BODY
*/

  .navbar-body{
    display: none;

    > .container {
      border: 1px solid transparent;
      overflow: hidden;
      -webkit-box-shadow: 0 40px 40px -20px #666;
         -moz-box-shadow: 0 40px 40px -20px #666;
              box-shadow: 0 40px 40px -20px #666;
      background-color: rgba(239, 235, 238, 0.975);
      padding-bottom: 50px;
    }

  }

  .btn-primary {
    display: inline-block;
    padding: 10px 30px;
    color: #fff;
    font-size: 0.875rem;
    font-weight: 500;
  }


  /*
  CATEGORY
  */

    .nav-category-block {
      padding: 15px 30px 50px 15px;
      margin-bottom: -10000px;
      padding-bottom: 10000px;

      a h2 {
        color: #000;
      }

    }

    .nav-category-heading-block {
      .nav-list-block {
        a {
          &:hover {
            color: $link-color;
            h2 {
              color: $link-color;

              &:after {
                background:url('../images/nav/arrow-on.png') no-repeat;
                background-size: contain;
              }
            }
          }
        }
        h2 {
          position: relative;
          padding-right: 20px;

          &:after {
            content: '';
            background:url('../images/nav/arrow-off.png') no-repeat;
            background-size: contain;
            width: 11px;
            height: 18px;
            position: absolute;
            bottom: 10px;
            right: 0;
          }
        }
      }
    }

    .nav-category-list {
      padding-top: 20px;
      margin-left: 15px;
      margin-right: 15px;
      border-top: 1px solid $lighter-grey-2;
    }

    .nav-list-block {
      padding-right: 0;

      h2 {
        margin: 30px 15px 20px 15px;
      }

    }

    .nav-list {

      li + li {
        margin-top: 15px;
      }

      a {
        font-weight: bold;
        color: #000;

        &:hover {
          color: $link-color;
        }
      }

    }

    .nav-category-view-all-block {
      margin-top: 50px;

      a {
        margin-left: 15px;
        margin-right: 15px;
      }

    }

  /*
  ONSELL
  */

  .nav-onsell-block {
    background-color: rgba(246, 246, 246, 0.975);
    margin-bottom: -10000px;
    padding-bottom: 10000px;
    padding-top: 15px;

    .nav-list-block {
      h3 {
        margin-top: 30px;
        margin-bottom: 20px;
      }
    }

    /*
    .nav-list-block:after {
      position: relative;
      left: 15px;
      content: '';
      width: 50px;
      height: 1px;
      display: inline-block;
      border-bottom: 1px solid #000;
    }
    */

    .nav-list-block:last-child:after {
      display: none;
    }

    .nav-list-body {
      padding-left: 15px;
      padding-right: 15px;
    }

    .nav-list {
      margin-bottom: 30px;
      font-size: 0.75rem;
    }

    .view-all-deals {
      padding-left: 15px;
    }

    .view-all-deals:hover {
      color:#e6007e;
    }

  } // nav-onsell-block END

  /*
  CLOSE BUTTON
  */
  .btn-close-container {
    width: 100%;
    text-align: right;
  }

  .btn-close-nav {
    background: url('../images/nav/close.png') no-repeat;
    width: 26px;
    height: 26px;
    display: inline-block;
    cursor: pointer;
  } // close button END

} // navbar (desktop) END


/*
XMAS THEME (DESKTOP)
*/
.xmas {
  .navbar-top {
    .container {
      background-image: url('../images/nav/xmas-nav-bg.png');
      background-color: #ffffff;
      background-repeat: no-repeat;
      background-position: 40%;
      background-size: contain;
    }
  }

  #navbar {
    .xmas-buy {
      .nav-category-block {
        &:after {
          content: '';
          // background: url('../images/nav/xmas-tree-desktop.png') no-repeat;
          background: url('../images/nav/xmas-tree-mobile.png') no-repeat;
          background-position: right bottom;
          // background-size: 118px 200px;
          background-size: contain;
          width: 33.33%;
          // height: 200px;
          height: 150px;
          display: block;
        }
      }
    }

    .xmas-three {
      .nav-category-block {
        &:after {
          content: '';
          background: url('../images/nav/xmas-flower-mobile.png') no-repeat;
          // background-position: 75% 100%;
          background-position: 70% 80%;
          // background-size: 182px 182px;
          background-size: contain;
          width: 100%;
          // height: 150px;
          height: 130px;
          display: block;
        }
      }
    }
  }
}

/*
NAVBAR BODY (MOBILE)
*/

#navbar-xs {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: -100%;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background-color: rgba(255,255,255,0.975);
  @include offCanvasTransition(0.5s);
  -webkit-overflow-scrolling: touch;

  &.is-expanded {
    display: block;
    right: 0;
  }

  // Top

  .navbar-top-border {
    @include backgroundImageGradient;
    height: 2px;
  }

  .navbar-top, .navbar-body {
    float: left;
    width: 100%;
  }

  .navbar-top {
    padding: 0 15px;
  }

  .navbar-top-left {
    height: $navbar-xs-height;

    a {
      display: inline-block;
      height: 100%;
    }

    a:hover {
      text-decoration: none;

      img {
        text-decoration: underline;
      }
    }

  }

  .navbar-top-right {
    height: $navbar-xs-height;
    text-align: right;
  }

  .navbar-icon + .navbar-label {
    margin-left: 15px;
    text-transform: capitalize;
  }

  .navbar-label {
    line-height: 1em;
  }

  .navbar-close {
    background: none;
    border: 0;

    img {
      width: 13px;
      height: auto;
    }

  }

  // Body

  .navbar-body {
    background-color: rgba(255,255,255,0.25);
  }

  .nav-list-body {

    h2 {
      display: block;
      margin: 0 15px;
      padding-bottom: 5px;
      border-bottom: 1px solid $lighter-grey-2;
      font-size: 1.875rem; //2.5rem
      text-transform: uppercase;
      position: relative;

      &:after {
        content: '';
        background:url('../images/nav/arrow-off.png') no-repeat;
        background-size: contain;
        width: 20px;
        height: 25px;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

  }

  .nav-list-body:first-child {
    margin-top: 30px;
  }

  .nav-list-body + .nav-list-body {
    margin-top: 60px;
  }

  .nav-list {

    li {
      padding: 20px 15px 20px 30px;

      a {
        font-weight: bold;
        font-size: 1.25em;
        color: #000;
      }

    }

    li.active {
      @include backgroundImageGradient;

      a {
        color: #fff;
      }

    }

  }

  // Trending Offers
  .nav-onsell-block {
    h3 {
      display: inline-block;
      margin-left: 15px;
      padding-bottom: 10px;
      border-bottom: 1px solid $black;
    }

    .nav-list {
      margin-bottom: 30px;
      font-size: 0.75em;
    }
  }

  .navbar-3business {
    float: left;
    margin-top: 50px;
    margin-left: 20px;

    img {
      max-width: 150px;
    }

  }

  .navbar-footer {
    float: left;
    width: 100%;
    padding: 30px 15px;
    text-align: center;

    .navbar-footer-icon {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0;
      max-width: 60.5px;
    }

    .navbar-footer-block {
      position: relative;
      display: inline-block;
      width: auto;
      text-align: left;
      padding-left: 75.5px;
    }

    h3 {
      font-size: 1.7rem;
      margin-bottom: 0;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
      color: #666;
      font-weight: bold;
      font-size: 0.85em;
    }

    img {
      width: 50%;
      height: auto;
    }

  }

} // navbar (mobile) END

// desktop, tablet
@media screen and (min-width: $screen-md-min) {

/*
NAV BAR DESKTOP
*/

  #navbar{

    height: 72px;

    .navbar-logo-block {

      a {
        position: relative;
        display: inline-block;
      }

      a:after {
        position: absolute;
        top: 0%;
        right: -30px;
        content:' ';
        display: inline-block;
        border-left: 1px solid #000;
        height: 100%;
      }

    }

  }

  #navbar-xs {
    display: none !important;
  }

  /* .xmas {
    .navbar-top {
      .container {
        background-size: 170px 45px;
      }
    }
  } */

}

@media screen and (min-width:$screen-md-min) and (max-width: $screen-md-max) {
  #navbar{
    .navbar-top-right-list {
      li + li {
        margin-left: 20px;
      }
    }
  }

  .xmas {
    .navbar-top {
      .container {
        background-image: url('../images/nav/xmas-nav-bg-tablet.png');
      }
    }
  }
}


@media screen and (max-width: $screen-xs-max) {

/*
NAV BAR TOP
*/

  .navbar-top {

    .navbar-label {
      height: $navbar-xs-height;
    }

  }

  #navbar {

    .navbar-logo-block {
      height: $navbar-xs-height;
    }

    .navbar-logo {
      height: 35px;
    }

    .navbar-searchIcon {
        display: none !important;
    }


    .navbar-searchIcon-xs, .navbar-hamburger-xs {
      display: inline-block !important;
    }

    .navbar-top-right-list {

      li {
        height: $navbar-xs-height;
      }

      li + li {
        margin-left: 20px;
      }

      // Destktop login icon
      .subnavlogin-wrapper {
        display: none;
      }

    }

    .navbar-body {
      display: none !important;
    }
  }

  /* XMAS THEME */
  .xmas {
    .navbar-top {
      .container {
        background: none;
      }
    }

    #navbar-xs {
      .navbar-top {
        background: url('../images/nav/xmas-nav-bg-mobile.png') no-repeat;
        background-position: top center;
        background-size: contain;
        // background-size: 50% 100%;
      }

      .navbar-body {
        .nav-list-body {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            background-position: right bottom;
            background-repeat: no-repeat;
            display: block;
          }

          &:first-of-type {
            &:after {
              background-image: url('../images/nav/xmas-tree-mobile.png');
              background-size: contain;
              width: 120px;
              height: 130px;
              // background-position: 70% 100%;
              right: 5%;
              bottom: -15%;
            }
          }

          &:last-of-type {
            &:after {
              background-image: url('../images/nav/xmas-flower-mobile.png');
              background-size: contain;
              width:130px;
              height: 124px;
              // background-position: 70% 100%;
              right: 5%;
              bottom: -15%;
            }
          }
        }
      }
    }
  }

}