.section-roaming-service {
  color: $white;

  .container {
    @include backgroundImageWidget;
    // background-size: 200%;
    // background-position: 0 85%;
    width: 100%;
    height: 100%;
  }

  .section-inner {
    padding: 60px 70px;
  }

  .headline-logo {
    display: inline-block;
    width: 75px;
    height: 100px;
    vertical-align: top;

    img {
      width: 100%;
    }
  }

  .headline {
    display: inline-block;
    margin-left: 20px;
    width: calc(100% - 95px);

    p {
      margin: 5px 0;
    }
  }

  .service-card {
    /* background: $white;
    border-radius: 10px; */
    //margin: 0 30px 0 0;
    //width: 22.5%;
    &:last-of-type {
      margin-right: 0;
    }
  }

  .service-card-container {
    display: block;
    width: 100%;
    -webkit-box-shadow: 4px 4px 15px 0 rgba(64, 64, 64, 0.5);
    box-shadow: 4px 4px 15px 0 rgba(64, 64, 64, 0.5);
    -webkit-transition: -webkit-transform 400ms;
    transition: -webkit-transform 400ms;
    -o-transition: transform 400ms;
    transition: transform 400ms;
    -webkit-font-smoothing: subpixel-antialiased;

    &:hover {
      -webkit-transform: scale3d(1.02, 1.02, 1.02);
      transform: scale3d(1.02, 1.02, 1.02);
      -webkit-font-smoothing: subpixel-antialiased;
    }
  }

  .content {
    padding: 10px;
    margin: 10px;
    background: $white;
    border-radius: 10px;
  }

  .service-card-container {
    background: $white;
    border-radius: 10px;
    cursor: pointer;
  }

  .service-card-top {
    padding: $padding8x 70px 0;
  }

  .service-card-body {
    color: $purple;
    padding: 0 $padding7x;
    height: 100px;
    position: relative;
  }

  .service-content {
    position: absolute;
    bottom: 0;
    left: 35px;
    right: 35px;

    h3 {
      font-size: $font-24px;

      &:after {
        content: '';
        border-bottom: 2px solid $black;
        position: absolute;
        bottom: -20px;
        width: 40px;
        display: block;

      }
    }
  }

  .col-hr {
    hr {
      margin: 50px 0;
    }
  }

  .service-card-footer {
    color: $black;
    margin-top: 40px;
    padding: 0 $padding7x $padding4x;
    position: relative;
    height: 140px;
  }

  .service-card-footer-inner {
    position: absolute;
    top: 0;
    left: 35px;
    right: 35px;
  }

  .row-section-roaming-service-footer {
    margin-top: 60px;

    .btn {
      margin: 0 auto;
      width: 440px;
      padding: 0 $padding6x;
      line-height: 60px;
      height: 60px;
    }
  }
}

/* Mobile / Tablet */
@media screen and (max-width: $screen-md-max) {
  .section-roaming-service {

    .section-inner {
      padding: $padding6x $padding4x;
    }

    .service-card-top {
      padding: $padding4x;
    }

    .service-card-body,
    .service-card-footer {
      padding: 0 $padding4x;
    }

    .service-card-top {
      figure {
        width: 80%;
        margin: 0 auto;
      }
    }

    .service-card-body {
      height: 75px;
    }

    .service-content {
      left: 20px;
      right: 20px;
      h3 {
        font-size: $font-20px;
      }
    }

    .service-card-footer {
      min-height: 140px;
    }

    .service-card-footer-inner {
      left: 20px;
      right: 20px;

      p {
        font-size: $font-14px;
      }
    }

    .col-hr {
      hr {
        margin: 10px 0 30px;
      }
    }
  }
}
@media screen and (max-width: $screen-sm-max ) {
  .section-roaming-service {

    .section-inner {
      padding: $padding6x $padding4x;
    }

    .service-card-scroller {
      overflow-x: auto;
      white-space: nowrap;
      margin: 0 -35px;
      -webkit-overflow-scrolling: touch;
    }

    .service-card {
      float: none;
      // display: inline-block;
      white-space: normal;
    }

    .service-card-body,
    .service-card-footer {
      padding: 0 $padding4x;
    }

    .service-card-top {
      figure {
        width: 50%;
        margin: 0 auto;
      }
    }

    .service-card-body {
      height: 75px;
    }

    .service-content {
      left: 20px;
      right: 20px;
      h3 {
        font-size: $font-20px;
      }
    }

    .service-card-footer {
      min-height: 120px;
    }

    .service-card-footer-inner {
      left: 20px;
      right: 20px;
    }

    .view-more-link {
      color: $white;
      font-size: $font-16px;
      text-align: left;
      text-decoration: underline;
      margin-top: $padding6x;
    }

    .row-section-roaming-service-footer {
      margin-top: 0;
    }
  }
}