.hero-slider {
  color: $white;
  margin: 0 -15px;


  a {
    color: $white;
    text-decoration: none;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  .row {
    margin: 0;
  }

  .promo-item {
    height: auto;
  }

  .promo-image {
    width: 100%;
  }

  .promo-box,
  .promo-cta {
    padding: 0;
  }

  .hide-overlay {
    .promo-box {
      display: none;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .promo-text-bg {
    // @include backgroundImageGradient;
    @include backgroundImagePromoSlider;
    // background-size: 200%;
    background-size: 100% 100%;
    opacity: 0.85;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .promo-text-container {
    // padding: 85px $padding11px;
    padding: 60px 50px 50px;
    height: 430px;
    margin-top: 15%;
    position: relative;
    z-index: 9999;

    h1 {
      font-size: $font-70px;
      margin: 0 0 -20px;
      /* fixed height to 2 rows */
      height: 170px;
      overflow: hidden;

      img {
        display: inline-block;
      }
    }

    h5 {
      font-size: $font-20px;
      margin-bottom: $padding7x;
    }

    hr {
      margin: $padding8x 0;
    }
  }

  .social-icons {
    position: absolute;
    top: 30px;
    right: 0;

    a {
      display: inline-block;
      margin-right: 25px;
      cursor: pointer;
    }

    .icon-fb {
      background: url('../images/icons/icon-facebook-white.png') no-repeat;
      background-size: contain;
      width: 10px;
      height: 20px;
      display: block;
    }

    .icon-wechat {
      background: url('../images/icons/icon-wechat-white.png') no-repeat;
      background-size: contain;
      width: 22px;
      height: 20px;
      display: block;
    }
  }

  .social-share {
    display: inline-block;

    .wechat-qrcode {
      display: none;
      position: absolute;
      top: 0;
      left: -220px;
      width: 200px;
      height: 192px;
      color: $dark-grey;
      @include font-regular;
      font-size: $font-12px;
      text-align: center;
      background-color: $white;
      border: 1px solid #eeeeee;
      z-index: 100;
      transition: all 200ms;

      &:after {
        content: '';
        position: absolute;
        top: 5px;
        left: 199px;
        height: 0;
        width: 0;
        border: solid transparent;
        border-left-color: $white;
        border-width: 6px 6px 8px 8px;
        margin-top: -6px;
      }

      h4 {
        @include font-regular;
        font-weight: normal;
        height: 26px;
        line-height: 26px;
        font-size: 12px;
        margin: 0;
        padding: 0;
      }

      .qrcode {
        width: 105px;
        margin: 10px auto;
      }

      .help {
        p {
          font-weight: normal;
          line-height: 16px;
          padding: 0;
          margin: 0;
        }
      }
    }

    .icon-wechat {
      position: relative;

      &:hover {
        .wechat-qrcode {
          display: block;
        }
      }
    }
  }

  .promo-texts {
    opacity: 0;
    transition: opacity 1s ease-in-out;
    z-index: 10;
    position: relative;
  }

  .promo-cta {
    text-align: center;
    display: inline-block;
  }

  .promo-slide-control {
    // padding: $padding3x $padding7x;
    padding: 0 $padding7x;
    line-height: 60px;
    height: 60px;
    display: inline-block;
  }

  .btn-primary {
    width: 100%;
    line-height: 60px; // make the button with height 60px
    height: 60px;
    font-size: $font-14px;
    padding: 0 20px;
    text-transform: uppercase;
  }

  .dots-container {
    display: inline-block;
    vertical-align: middle;
  }

  .btn-prev,
  .btn-next {
    display: inline-block;
    width: 9px;
    height: 16px;
    background-size: 9px 16px;
    z-index: 10;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .btn-prev {
    margin-right: $padding6x;
  }

  .btn-next {
    margin-left: $padding6x;
  }

  // Slick Carousel Custom Style
  .slick-dots {
    list-style: none;
    position: relative;

    > li {
      display: inline-block;
      width: 20px;
      font-size: $font-16px;
      @include font-bold;
      margin-right: $padding5x;

      &:last-child {
        margin: 0;
      }

      a {
        color: $lighter-grey-2;
        cursor: pointer;
        display: inline-block;
      }
    }

    .slick-active {
      a {
        color: $pink;
      }
    }
  }

  /* Active Slide */
  .slick-active {
    .promo-box {
      .promo-texts {
        opacity: 1;
      }
    }
  }
}

/* Tablet */
@media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .hero-slider {
    .promo-text-container {
      margin-top: 8%;
    }
    .promo-slide-control {
      padding: 0 15px;
    }
    .slick-dots > li {
      margin-right: 20px;
    }
    .btn-prev {
      margin-right: 20px;
    }
    .btn-next {
      margin-left: 20px;
    }
    /* .slick-dots > li {
      margin-right: 15px;
    } */
  }
}

/* Mobile / Tablet */
@media screen and (max-width: $screen-sm-max ) {
  .hero-slider {
    .promo-box {
      position: absolute;
      bottom: 10%;
    }

    .promo-text-container {
      padding: $padding8x $padding6x;
      height: auto;
      // margin-top: 57%;

      h1 {
        font-size: $font-36px;
        height: auto;
        max-height: 88px;
        margin: 0 0 -10px;
      }

      h5 {
        font-size: $font-12px;
        margin-bottom: $padding3x;
      }

      p {
        font-size: $font-12px;
      }

      hr {
        margin: $padding4x 0;
      }
    }

    .slick-dots {
      position: absolute;
      bottom: $padding5x;

      & > li {
        margin-right: $padding2x;
      }
    }
  }
}