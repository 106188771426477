// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
.footer {

  .container {
    background-color: $black;
    border-top: 3px solid $pink;
    padding: 70px 70px 50px;
  }

  .footer-upper-container{
    border-bottom: 1px solid $footer-text-color;
    padding-left: 55px;
    padding-bottom: 1rem;
  }

  .footer-directory-list {

    h6 {
      color: $white;
      font-size: $font-16px;
      @include font-bold;
      margin-bottom: $padding4x;
    }

    li {
      margin-bottom: $padding6x;

      a {
        color: $footer-text-color;
        font-size: $font-20px;
        @include font-light;
      }
    }

    // span {
    //   background-size: 22px 22px;
    //   width: 22px;
    //   height: 22px;
    //   display: inline-block;
    //   margin-right: $padding3x;
    //   vertical-align: middle;
    // }

    .col-footer-contact {
      a {
        width: 80%;
        white-space: normal;
        display: inline-block;
        vertical-align: top;
      }
    }

    .footer-icon {
      width: 22px;
      height: 22px;
      display: inline-block;
      margin-right: $padding3x;
      vertical-align: middle;

      img {
        width: 22px;
        height: 22px;
      }
    }
  }

  .footer-legal-list {
    padding-left: 55px;
    margin-top: $padding5x;

    li {
      display: inline-block;
      border-left: 1px solid $white;
      padding-left: $padding6x;
      padding-right: $padding6x;

      &:first-child {
        border-left: none;
        padding-left: 0;
      }

      a {
        color: $white;
        font-size: $font-20px;
      }
    }
  }

  .copyright {
    color: $footer-text-color;
    font-size: $font-16px;
    margin-top: $padding8x;
  }

  .holdings {
    display: inline-block;
    text-align: right;
    margin: 0;
    padding: 0;

    a {
      width: 250px;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      margin: 30px 0 0 30px;

      &:first-child {
        width: 140px;
        margin-left: 0;
      }
    }
  }

  .social-container {
    a {
      margin-right: $padding3x;
      display: inline-block;
    }

    img {
      width: 30px;
      height: 30px;
    }

    .wechat-share-tooltip {
      position: relative;
      width: 1px;
      height: 37px;
      display: inline-block;

      .wechat-qrcode {
        display: none;
        position: absolute;
        top: 6px;
        left: -260px;
        width: 200px;
        height: 192px;
        color: $dark-grey;
        @include font-regular;
        font-size: $font-12px;
        text-align: center;
        background-color: $white;
        border: 1px solid #eeeeee;
        z-index: 100;
        transition: all 200ms;

        &:after {
          content: '';
          position: absolute;
          top: 5px;
          left: 199px;
          height: 0;
          width: 0;
          border: solid transparent;
          border-left-color: $white;
          border-width: 6px 6px 8px 8px;
          margin-top: -6px;
        }

        h4 {
          @include font-regular;
          font-weight: normal;
          height: 26px;
          line-height: 26px;
          font-size: 12px;
          margin: 0;
          padding: 0;
        }

        .qrcode {
          width: 100px;
          height: 100px;
          margin: 10px auto;
        }

        img {
          width: 100px;
          height: 100px;
        }

        .help {
          p {
            font-weight: normal;
            line-height: 16px;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }

  .col-download-app {
    margin-top: 70px;
  }
}


/* Mobile / Tablet */
@media screen and (max-width: $screen-sm-max ) {
  .footer {

    .container {
      padding: $padding9x $padding3x $padding7x;
    }

    .col {
      margin-bottom: 80px;
    }

    .footer-upper-container{
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 1rem;
      padding-top: 1rem;
      // border-bottom: 0;
    }

    .footer-directory-list {
      li {
        a {
          font-size: $font-16px;
        }
      }

      .footer-icon {
        // background-size: 18px 18px;
        width: 18px;
        height: 18px;

        img {
          width: 18px;
          height: 18px;
        }
      }
    }

    .social-container {
      a {
        margin: 0 $padding4x $padding3x 0;
      }
      img {
        width: 60px;
        height: 60px;
      }

      .wechat-share-tooltip {
        .wechat-qrcode {
          left: -300px;
          top: -23px;
        }
      }
    }

    .footer-legal-list {
      padding: 0 0 2rem 0;
      margin: 0;
    }

    .col-download-app {
      margin-top: $padding9x;
    }

    .copyright {
      color: $white;
      opacity: 0.3;
      font-size: $font-12px;
      @include font-light;
      padding: 0;
      margin-top: $padding5x;
    }

    .holdings {
      // margin: 30px -15px 0;
      text-align: left;
      a {
        margin: 15px 0 0;
        display: block;
      }
    }
  }
}