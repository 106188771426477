/**
 * Basic typography style for copy text
 */
body {
  color: $text-color;
  font: 16px $text-font;
}

h1, h2, h3, h4, h5, h6 {
  @include font-bold;
  margin: 0;
}

h1 {
  font-size: 3.75em;
}

p {
  font-size: $font-14px;
}

/* Mobile / Tablet */
@media screen and (max-width: $screen-sm-max ) {
  h1 {
    font-size: $font-24px;
  }
}
